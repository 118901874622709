import { graphql } from "gatsby";
import React from "react";
import "react-multi-carousel/lib/styles.css";
import Header from '../components/Header/Header-2';
import Layout from '../components/Layout/Layout';
import SEO from "../components/seo";

const VideoHelp = () => {
    const seo = {
        metaDesc: 'Video Help Page - PowerPatent.com'
    }
    //
    return (
        <>
            <Layout>
                <SEO title="Video Help Page" description={'Video Help Page - PowerPatent.com'} canonical={'/video-help'} seo={seo} />
                <Header home={false} menu='video-help' />
                <main>
                    <section className="py-5 bg-sky">
                        <div className="container">

                            {/* 1 */}
                            <div className="py-4">
                                <div className="box-shadow anim-box bg-white">
                                    <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                                        <div className="col-md-6">
                                            <div>
                                                <iframe width="560" height="315" src="https://www.youtube.com/embed/SbSagf9erkA?si=ZoO110K4rVJRUH78" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="anim-title pb-4">Welcome</h3>
                                            <p className="anim-desc">Welcome to PowerPatent, the fastest way to a complete first draft patent application.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 2 */}
                            <div className="py-4">
                                <div className="box-shadow anim-box bg-white">
                                    <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                                        <div className="col-md-6">
                                            <div>
                                                <iframe width="560" height="315" src="https://www.youtube.com/embed/DNji_Y3pqdc?si=ig4xlgKQsb3a54M0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="anim-title pb-4">Overview</h3>
                                            <p className="anim-desc">
                                            The 3 approaches to using PowerPatent to create first draft patent applications include 1) the traditional approach, 2) claims first approach and 3) invention disclosure approach.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 3 */}
                            <div className="py-4">
                                <div className="box-shadow anim-box bg-white">
                                    <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                                        <div className="col-md-6">
                                            <div>

                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/-QHYiGW2etQ?si=MTbCRr1qU3UHFqFk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                            </div>
                                            
                                            </div>
                                        <div className="col-md-6">
                                            <h3 className="anim-title pb-4">Start Traditional Flow</h3>
                                            <p className="anim-desc">
                                            This flow is the traditional way of drafting patent applications by providing the Title, Background, and Summary of the invention.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            {/* 4 */}
                            <div className="py-4">
                                <div className="box-shadow anim-box bg-white">
                                    <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                                        <div className="col-md-6">
                                            <div>
                                                <iframe width="560" height="315" src="https://www.youtube.com/embed/ZKwrLliUcf0?si=g4ipCtVgJV3w7MYN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="anim-title pb-4">Autogen Background</h3>
                                            <p className="anim-desc">
                                            How to automatically generate the Background section from the Title
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                                                    
                            {/* 5 */}
                            <div className="py-4">
                                <div className="box-shadow anim-box bg-white">
                                    <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                                        <div className="col-md-6">
                                            <div>
                                                <iframe width="560" height="315" src="https://www.youtube.com/embed/eO8x5HjYj9Y?si=XYIJtxLq3VwMTxWp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                            
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="anim-title pb-4">Summary</h3>
                                            <p className="anim-desc">
                                            How to add Summary of the Invention
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                                    
                                                    
                            {/* 6 */}
                            <div className="py-4">
                                <div className="box-shadow anim-box bg-white">
                                    <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                                        <div className="col-md-6">
                                            <div>
                                                <iframe width="560" height="315" src="https://www.youtube.com/embed/04l-egqY-7E?si=PVk_IcYuuq07V7I_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                            
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="anim-title pb-4">Advantages</h3>
                                            <p className="anim-desc">
                                            How to autogenerate the advantages of the invention
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                                                    
                            {/*  7 */}
                            <div className="py-4">
                                <div className="box-shadow anim-box bg-white">
                                    <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                                        <div className="col-md-6">
                                            <div>
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/oFhGSm--LLU?si=rbpirRS7LnK6MzJj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="anim-title pb-4">Abstract</h3>
                                            <p className="anim-desc">
                                            How to autogenerate the Abstract from the Summary section
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                                                    
                            {/* 8 */}
                            <div className="py-4">
                                <div className="box-shadow anim-box bg-white">
                                    <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                                        <div className="col-md-6">
                                            <div>
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/PLkXf7UEc18?si=B5VM78_Pcs1duQXX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="anim-title pb-4">Claim Editing</h3>
                                            <p className="anim-desc">
                                            How to edit claims assisted with Section 112 checking
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                                    
                            {/* 9 */}
                            <div className="py-4">
                                <div className="box-shadow anim-box bg-white">
                                    <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                                        <div className="col-md-6">
                                            <div>
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/1BaqBlqXSAU?si=f9jseGyxz8sop2Lq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="anim-title pb-4">Claims As Input</h3>
                                            <p className="anim-desc">
                                            How to enter claims, visualize them, and do drag-and-drop edit of claims
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                                                    
                            {/* 10 */}
                            <div className="py-4">
                                <div className="box-shadow anim-box bg-white">
                                    <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                                        <div className="col-md-6">
                                            <div>
                                                <iframe width="560" height="315" src="https://www.youtube.com/embed/OiHHp6mVQB0?si=_SHmIUWZAjDCyFhQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="anim-title pb-4">Spec from Claims</h3>
                                            <p className="anim-desc">
                                            How to generate detailed description text from claims
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                                    
                            {/* 11 */}
                            <div className="py-4">
                                <div className="box-shadow anim-box bg-white">
                                    <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                                        <div className="col-md-6">
                                            <div>
                                                <iframe width="560" height="315" src="https://www.youtube.com/embed/E5oyPhsydYE?si=e9-m9ntF89wgQG2K" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="anim-title pb-4">Figures or Flowcharts</h3>
                                            <p className="anim-desc">
                                            How to generate flowcharts or upload drawings
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                                                    
                            {/* 12 */}
                            <div className="py-4">
                                <div className="box-shadow anim-box bg-white">
                                    <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                                        <div className="col-md-6">
                                            <div>
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/wae-JMiEx1o?si=5-6lAe3DzMBVnmMi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="anim-title pb-4">Description For Drawings</h3>
                                            <p className="anim-desc">
                                            How to generate description text from drawing part list/annotations
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                                    
                            {/* 13 */}
                            <div className="py-4">
                                <div className="box-shadow anim-box bg-white">
                                    <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                                        <div className="col-md-6">
                                            <div>
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/2yaFsWXFNtU?si=4BfkUYh3ZnvF1ohX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="anim-title pb-4">Chatbox</h3>
                                            <p className="anim-desc">
                                            How to use chatbot to add extra text to the application
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                                                    
                            {/* 14 */}
                            <div className="py-4">
                                <div className="box-shadow anim-box bg-white">
                                    <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                                        <div className="col-md-6">
                                            <div>
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/irkin6A8XDk?si=09lHdVYDBWHOf4ol" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="anim-title pb-4">Diagnostics</h3>
                                            <p className="anim-desc">
                                            Section 112 Diagnostics for patent applications
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                                                                
                            {/* 15 */}
                            <div className="py-4">
                                <div className="box-shadow anim-box bg-white">
                                    <div className="row gap-md-0 gap-4 align-items-top flex-md-row-reverse flex-column">
                                        <div className="col-md-6">
                                            <div>
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/sZG8g_TMhf8?si=2Hbqww3LnO1f3Meq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="anim-title pb-4">Novelty Search</h3>
                                            <p className="anim-desc">
                                            Interactive search based on claims
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                </main>
            </Layout>
        </>
    )
}
export default VideoHelp
